import { template as template_3e87d58917d147c19d8e1d17fad6c796 } from "@ember/template-compiler";
const FKText = template_3e87d58917d147c19d8e1d17fad6c796(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
