import { template as template_69254a9549e44ddd9272a568aeca344b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_69254a9549e44ddd9272a568aeca344b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
