import { template as template_2d5e15c9febf4e69b5b360727ef80cbf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2d5e15c9febf4e69b5b360727ef80cbf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
