import { template as template_db8666866a0e4367939cd6f32dc2bc46 } from "@ember/template-compiler";
const WelcomeHeader = template_db8666866a0e4367939cd6f32dc2bc46(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
