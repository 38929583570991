import { template as template_632152edc0fd4c3f90354abc3f61253e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_632152edc0fd4c3f90354abc3f61253e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
