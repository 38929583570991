import { template as template_60b9a67084074f86a5d47cd12dca4264 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_60b9a67084074f86a5d47cd12dca4264(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
