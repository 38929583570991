import { template as template_9fdc7b5a6ccc434bba82d00db1787a6f } from "@ember/template-compiler";
const FKLabel = template_9fdc7b5a6ccc434bba82d00db1787a6f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
